<template>
  <div class="football-list-wrap">
    <!-- 完赛赛程 -->
    <div class="foot-ball-title completed" v-if="routerName === 'completed'">
      <div class="league">联赛</div>
      <div class="time">时间</div>
      <div class="statues">状态</div>
      <div class="home">主场球队</div>
      <div class="score">比分</div>
      <div class="away">客场球队</div>
      <div class="half">半场</div>
      <div class="corner">角球</div>
      <div class="success">胜负</div>
      <div class="handicap">让球</div>
      <div class="goals">进球数</div>
      <div class="data">数据</div>
    </div>
    <!-- 未来赛程 -->
    <div class="foot-ball-title schedule" v-else-if="routerName === 'schedule'">
      <div class="league">联赛</div>
      <div class="time">时间</div>
      <div class="statues">状态</div>
      <div class="home">主场球队</div>
      <div class="score">比分</div>
      <div class="away">客场球队</div>
      <div class="video">直播</div>
      <div class="odd">让球</div>
      <div class="live">解说</div>
      <div class="data">数据</div>
      <div class="play">操作</div>
    </div>
    <div class="foot-ball-title live" v-else>
      <div class="league">联赛</div>
      <div class="time">时间</div>
      <div class="statues">状态</div>
      <div class="home">主场球队</div>
      <div class="score">比分</div>
      <div class="away">客场球队</div>
      <div class="half">半场</div>
      <div class="corner">角球</div>
      <div class="video">直播</div>
      <div class="odd">指数</div>
      <div class="data">数据</div>
      <div class="live">解说</div>
      <div class="play">操作</div>
    </div>
    <div class="football-list-wrap">
      <template v-if="routerName === 'collect'">
        <!-- 收藏赛事 -->
        <template v-if="userCollectList.length > 0">
          <div class="football-list">
            <template v-for="(item, idx) in userCollectList">
              <footballItem
                :info="item"
                :key="`start-${item.tournament_id}-${idx}`"
                :collectIds="localCollectIds"
                @getAnchor="getAnchor"
                @toTop="toTop"
                @collect="collect"
              />
            </template>
          </div>
        </template>
      </template>
      <template v-else-if="routerName === 'completed'">
        <!-- 完赛赛事 -->
        <template v-if="completedList.length > 0">
          <div class="football-list">
            <template v-for="(item, idx) in completedList">
              <footballCompletedItem
                :info="item"
                :key="`start-${item.tournament_id}-${idx}`"
                :collectIds="localCollectIds"
                @getAnchor="getAnchor"
                @toTop="toTop"
                @collect="collect"
              />
            </template>
          </div>
        </template>
      </template>
      <template v-else-if="routerName === 'schedule'">
        <!-- 未来赛事 -->
        <template v-if="scheduleList.length > 0">
          <div class="football-list">
            <template v-for="(item, idx) in scheduleList">
              <footballScheduleItem
                :info="item"
                :key="`start-${item.tournament_id}-${idx}`"
                :collectIds="localCollectIds"
                @getAnchor="getAnchor"
                @toTop="toTop"
                @collect="collect"
              />
            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <!-- 置顶赛事 -->
        <template v-if="topList.length > 0">
          <div class="football-list">
            <template v-for="(item, idx) in topList">
              <footballItem
                :info="item"
                :key="`start-${item.tournament_id}-${idx}`"
                :collectIds="localCollectIds"
                @getAnchor="getAnchor"
                @toTop="toTop"
                @collect="collect"
              />
            </template>
          </div>
        </template>
        <!-- 进行中赛事 -->
        <template v-if="onStartList.length > 0">
          <div class="on-start statue-title">进行中</div>
          <div class="football-list">
            <template v-for="(item, idx) in onStartList">
              <footballItem
                :info="item"
                :key="`start-${item.tournament_id}-${idx}`"
                :collectIds="localCollectIds"
                @getAnchor="getAnchor"
                @toTop="toTop"
                @collect="collect"
              />
            </template>
          </div>
        </template>
        <template v-if="onWaitList.length > 0">
          <div class="on-wait statue-title">未开始</div>
          <div class="football-list">
            <template v-for="(item, idx) in onWaitList">
              <footballItem
                :info="item"
                :key="`wait-${item.tournament_id}-${idx}`"
                :collectIds="localCollectIds"
                @getAnchor="getAnchor"
                @toTop="toTop"
                @collect="collect"
              />
            </template>
          </div>
        </template>
        <template v-if="onEndList.length > 0">
          <div class="on-end statue-title">已结束</div>
          <div class="football-list">
            <template v-for="(item, idx) in onEndList">
              <footballItem
                :info="item"
                :key="`end-${item.tournament_id}-${idx}`"
                :collectIds="localCollectIds"
                @getAnchor="getAnchor"
                @toTop="toTop"
                @collect="collect"
              />
            </template>
          </div>
        </template>
      </template>
    </div>
    <div class="football-img-list">
      <p class="tips">
        <img src="@/assets/img/score/tips.png" alt="" />
        <span>图例说明：</span>
      </p>
      <div class="img-tip">
        <div class="tip-item">
          <img src="@/assets/img/score/goal.png" alt="" />
          <span>进球</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/penalty.png" alt="" />
          <span>点球</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/own-goal.png" alt="" />
          <span>乌龙</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/red-card.png" alt="" />
          <span>红牌</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/yellow-card.png" alt="" />
          <span>黄牌</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/yellow-red.png" alt="" />
          <span>两黄变一红</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/exchange.png" alt="" />
          <span>换人</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/corner.png" alt="" />
          <span>角球</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/live.png" alt="" />
          <span>动画</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/video.png" alt="" />
          <span>直播</span>
        </div>
        <div class="tip-item">
          <img src="@/assets/img/score/voice.png" alt="" />
          <span>解说</span>
        </div>
      </div>
    </div>
    <a-drawer
      :width="404"
      :visible="drawer"
      :direction="direction"
      :modal="false"
      @close="handleClose"
    >
      <template slot="title">
        <div class="drawer-title">解说</div>
      </template>
      <div class="drawer-body">
        <ul>
          <li
            class="anchor-item"
            v-for="(i, idx) in anchors"
            :key="`${idx}-${i.member_id}`"
          >
            <a
              :href="`/detail/1/${i.tournament_id}/${i.member_id}`"
              target="_blank"
            >
              <div class="anchor-img">
                <img :src="i.face" alt="" />
              </div>
              <p class="anchor-name">{{ i.nickname }}{{ i.type }}</p>
            </a>
          </li>
        </ul>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import dayjs from "dayjs";
import footballCompletedItem from "@/components/score/footballCompletedItem.vue";
import footballScheduleItem from "@/components/score/footballScheduleItem.vue";
import footballItem from "@/components/score/footballItem.vue";
import wsService  from "../../utils/websocket.js";
import storage from "@/utils/storage";
import { mapActions, mapState } from "vuex";
import bus from '@/utils/bus.js'
export default {
  name: "football-score",
  components: {
    footballItem,
    footballCompletedItem,
    footballScheduleItem,
  },
  props: {
    filterLeagues: Array,
  },
  data() {
    return {
      list: [],
      localTopIds: [],
      userCollectList: [],
      ftTimeWs: null,
      ftLiveWs: null,
      drawer: false,
      direction: "rtl",
      anchors: [],
      lock: false,
    };
  },
  computed: {
    ...mapState({
      scoreLanguage: "scoreLanguage",
    }),
    routerName() {
      return this.$route.name;
    },
    localCollectIds() {
      return (
        Array.from(
          this.userCollectList,
          ({ tournament_id }) => tournament_id
        ) || []
      );
    },
    completedList() {
      return (
        this.list.filter(
          (i) => i.state == -1 && this.filterLeagues.includes(i.league_id)
        ) || []
      );
    },

    scheduleList() {
      return (
        this.list.filter(
          (i) => i.state == 0 && this.filterLeagues.includes(i.league_id)
        ) || []
      );
    },
    filterList() {
      return (
        this.list.filter(
          (i) =>
            this.localTopIds.indexOf(i.tournament_id) < 0 &&
            this.filterLeagues.includes(i.league_id)
        ) || []
      );
    },
    topList() {
      return (
        this.list.filter((i) => this.localTopIds.includes(i.tournament_id)) ||
        []
      );
    },
    onStartList() {
      return this.filterList.filter((i) => i.state >= 1 && i.state <= 5) || [];
    },
    onWaitList() {
      return this.filterList.filter((i) => i.state == 0) || [];
    },
    onEndList() {
      return this.filterList.filter((i) => i.state < 0) || [];
    },
  },
  methods: {
    getAnchor(info) {
      this.$api
        .get(`/get_public_anchor?tournament_id=${info.tournament_id}&type=1`)
        .then((res) => {
          this.drawer = true;
          this.anchors = (res.data || {}).more || [];
        });
    },
    handleClose() {
      this.drawer = false;
      this.anchors = [];
    },
    getList() {
      const today = dayjs().format("YYYY-MM-DD");
      let day = this.$route.query.day
        ? dayjs(this.$route.query.day).format("YYYY-MM-DD")
        : today;

      if (["completed", "schedule"].includes(this.$route.name)) {
        day = day || dayjs(new Date()).format("YYYY-MM-DD");
      }
      let url = `/web/web_football_score_list?day=${day}`;
      if (["completed"].includes(this.$route.name)) {
        url = `${url}&state=-1`;
      }
      if (["schedule"].includes(this.$route.name)) {
        url = `${url}&state=0`;
      }

      this.$api.get(url).then((res) => {
        this.list = res.data || [];
      });
    },
    getCollectList() {
      this.$api.get(`/web/web_get_match_follow_list?genre=1`).then((res) => {
        if (res.code === 200) {
          this.userCollectList = res.data || [];
        }
      });
    },
    toTop(info) {
      if (!this.localTopIds.includes(info.tournament_id)) {
        this.localTopIds.push(info.tournament_id);
      } else {
        const idx = this.localTopIds.indexOf(info.tournament_id);
        this.localTopIds.splice(idx, 1);
      }

      localStorage.setItem(
        "football_top_ids",
        JSON.stringify(this.localTopIds)
      );
    },
    collect(info) {
      var token = storage.get("token");
      if (!token) {
        this.$parent.$refs.header.toLogin();
      } else {
        if (this.lock) {
          return false;
        }
        this.lock = true;
        let follow = 1;
        if (!this.localCollectIds.includes(info.tournament_id)) {
          follow = 1;
        } else {
          follow = 0;
        }
        this.$api
          .post(`/web/set_match_follow`, {
            tournament_id: info.tournament_id,
            genre: 1,
            is_follow: follow,
          })
          .then((res) => {
            if (res.code === 200) {
              if (follow == 1) {
                this.userCollectList.push(info);
              } else {
                const idx = this.userCollectList.findIndex(
                  ({ tournament_id }) => info.tournament_id === tournament_id
                );
                this.userCollectList.splice(idx, 1);
              }
              this.lock = false;
            }
          });
      }
    },
    // 建立连接、发起ws请求，以心跳方式，向服务端发送数据
    createWs() {
      this.ftTimeWs = new wsService(this.wsUrl, 5000, 3);
      this.ftLiveWs = new wsService(this.wsUrl, 5000, 3);
      this.ftTimeWs.init();
      this.ftLiveWs.init();
      this.ftTimeWs.onOpen(() => {
        this.ftTimeWs.sendMessage({ type: "init", task_id: "football_list" });
      });
      this.ftLiveWs.onOpen(() => {
        this.ftLiveWs.sendMessage({ type: "init", task_id: "footballlive" });
      });
      this.ftTimeWs.onMessage = this.receiveMsg;
      this.ftLiveWs.onMessage = this.receiveMsg;
    },
    receiveMsg(msg) {
      // 比赛时间更新
      if (msg.type === "on_time" || msg.type === "live") {
        const oList = JSON.parse(JSON.stringify(this.list));
        const nList = oList.map((item) => {
          const game =
            msg.result.filter(
              (wsGame) => wsGame.tournament_id == item.tournament_id
            )[0] || {};
          const newGame = Object.assign({}, item, game);
          return newGame;
        });
        nList.forEach((item) => {
          item.tournament_id = Number(item.tournament_id);
        });
        this.list = nList;
      }
      // 指数推送 处理 task_id: football_list 的指数推送，footballlive 的不管
      if (msg.type === "odds" && !msg.result.category) {
        const oList = JSON.parse(JSON.stringify(this.list));
        const nList = oList.map((item) => {
          const game =
            msg.result.filter(
              (wsGame) => wsGame.tournament_id == item.tournament_id
            )[0] || {};
          const newGame = Object.assign({}, item, game);
          return newGame;
        });
        this.list = nList;
      }
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.query) {
        this.list = [];
        this.getList();
        var token = storage.get("token");
        if (token) {
          this.getCollectList();
        }
      }
    },
  },
  mounted() {
    
    console.log("从新");
    this.localTopIds =
      JSON.parse(localStorage.getItem("football_top_ids")) || [];

    this.getList();
    this.createWs();
    var token = storage.get("token");
    if (token) {
      this.getCollectList();
    }
  },
  beforeDestroy() {
     this.ftTimeWs = null;
     this.ftLiveWs = null;
     this.ftTimeWs.close();
     this.ftLiveWs.close();
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/less/variables.less");

.football-list-wrap {
  margin-top: 16px;

  .foot-ball-title {
    display: flex;
    height: 54px;
    line-height: 54px;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    justify-content: space-between;
    text-align: center;

    &.live {
      .league {
        width: 92px;
      }

      .time {
        width: 80px;
      }

      .statues {
        width: 80px;
      }

      .home {
        width: 148px;
      }

      .score {
        width: 52px;
      }

      .away {
        width: 148px;
      }

      .half {
        width: 50px;
      }

      .corner {
        width: 82px;
      }

      .video {
        width: 50px;
      }

      .odd {
        flex: 1;
      }

      .data {
        width: 50px;
      }

      .live {
        width: 50px;
      }

      .play {
        width: 90px;
      }
    }

    &.schedule {
      .league {
        width: 92px;
      }

      .time {
        width: 92px;
      }

      .statues {
        width: 92px;
      }

      .home {
        width: 148px;
      }

      .score {
        width: 52px;
      }

      .away {
        width: 148px;
      }

      .video {
        width: 80px;
      }

      .odd {
        width: 80px;
      }

      .data {
        width: 80px;
      }

      .live {
        width: 80px;
      }

      .play {
        width: 80px;
      }
    }

    &.completed {
      .league {
        width: 92px;
      }

      .time {
        width: 92px;
      }

      .statues {
        width: 92px;
      }

      .home {
        width: 148px;
      }

      .score {
        width: 52px;
      }

      .away {
        width: 148px;
      }

      .half {
        width: 82px;
      }

      .corner {
        width: 82px;
      }

      .success {
        width: 100px;
      }

      .handicap {
        width: 100px;
      }

      .goals {
        width: 100px;
      }

      .data {
        width: 100px;
      }
    }
  }

  .football-list-wrap {
    .statue-title {
      height: 54px;
      line-height: 54px;
      position: relative;
      padding-left: 20px;
      font-size: 16px;
      color: #000;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 16px;
        height: 22px;
        width: 6px;
        border-radius: 4px;
      }

      &.on-start {
        &::after {
          background: #28c927;
        }
      }

      &.on-wait {
        &::after {
          background: #ffcd00;
        }
      }

      &.on-end {
        &::after {
          background: #cccccc;
        }
      }
    }

    .football-list {
      border-radius: 4px;
      overflow: hidden;
    }
  }

  .football-img-list {
    display: flex;
    align-items: center;
    margin-top: 36px;
    gap: 10px;

    .tips {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);

      img {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .img-tip {
      display: flex;

      .tip-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #000;
        margin-right: 20px;

        img {
          display: block;
          height: 17px;
          margin-right: 4px;
        }
      }
    }
  }
}

.drawer-title {
  color: #000;
  font-weight: bold;
  font-size: 16px;
}

.drawer-body {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;

    li {
      width: 74px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;

      .anchor-img {
        width: 46px;
        height: 46px;
        padding: 2px;
        border: 1px solid #ffcd00;
        border-radius: 50%;
        position: relative;

        &::after {
          position: absolute;
          bottom: -8px;
          content: "直播中";
          font-size: 12px;
          width: 48px;
          height: 18px;
          font-size: 12px;
          border-radius: 18px;
          background: #ffcd00;
          text-align: center;
          line-height: 18px;
          left: -1px;
          transform: scale(0.7);
        }



        img {
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      a {
        width: 74px;
        display: block;
        display: flex;
        flex-direction: column;
        align-items: center;

      }
      .anchor-name {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 20px;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: #000000;
      }
    }
  }
}
</style>
