// 封装 WebSocket 的基本功能
export default class wsService {
  constructor(url, reconnectInterval = 5000, maxReconnectAttempts = 3) {
    this.socket = null;
    this.url = url;
    this.reconnectInterval = reconnectInterval;
    this.maxReconnectAttempts = maxReconnectAttempts;
    this.reconnectAttempts = 0;
    this.pingInterval = null;
    this.reconnectTimeout = null;
    this.onOpenCallbacks = []; // 存储回调函数，以便在连接打开时调用
  }

  // 初始化 WebSocket 连接
  init() {
    this.socket = new WebSocket(this.url);
    this.socket.onopen = () => {
      console.log('WebSocket 已连接。');
      this.reconnectAttempts = 0;
      this.startPinging();
      this.onOpenCallbacks.forEach(callback => callback()); // 当连接打开时，执行所有回调
    };
    this.socket.onerror = this.handleError.bind(this);
    this.socket.onmessage = this.handleMessage.bind(this);
    this.socket.onclose = this.handleClose.bind(this);
  }
  onOpen(callback) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      callback();
    } else {
      this.onOpenCallbacks.push(callback); // 如果连接尚未打开，将回调函数添加到列表中
    }
  }
  // WebSocket 连接打开时的处理逻辑
  handleOpen() {
    console.log('WebSocket 已连接。');
    this.reconnectAttempts = 0; // 重置重连尝试次数
    this.startPinging();
  }

  // WebSocket 发生错误时的处理逻辑
  handleError(error) {
    console.error('WebSocket 出错：', error);
    this.reconnect();
  }

  // WebSocket 接收到消息时的处理逻辑
  handleMessage(message) {
    if (this.onMessage) {
      this.onMessage( JSON.parse(message.data));
    }
  }

  // WebSocket 关闭时的处理逻辑
  handleClose() {
    console.log('WebSocket 已断开。');
    this.stopPinging();
    this.reconnect();
  }

  // 开始发送心跳
  startPinging() {
    this.pingInterval = setInterval(() => {
      this.sendMessage({type: 'ping'});
    }, 5000);
  }

  // 停止发送心跳
  stopPinging() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  // 发送消息
  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.log('WebSocket 连接未开启，消息发送失败。');
      this.reconnect();
    }
  }

  // 尝试重新连接
  reconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      if (!this.reconnectTimeout) {
        this.reconnectTimeout = setTimeout(() => {
          console.log('尝试重新连接...');
          this.init();
          this.reconnectAttempts++;
          this.reconnectTimeout = null;
        }, this.reconnectInterval);
      }
    } else {
      console.log('重连尝试已达上限，停止重连。');
      this.handleReconnectFailed();
    }
  }

  // 处理重连失败
  handleReconnectFailed() {
    console.error('WebSocket 重连失败。');
    // 这里可以触发一些清理工作或者通知用户界面
    if (this.onReconnectFailed) {
      this.onReconnectFailed();
    }
  }

  // 关闭 WebSocket 连接
  close() {
    if (this.socket) {
      this.socket.close();
    }
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
  }
}
