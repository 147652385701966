<template>
  <div class="football-item-wrap">
    <div class="league" :style="{ color: info.color }">
      <template v-if="scoreLanguage === 'zh_TW'">
        {{ info.league_name_zht ? info.league_name_zht : info.league_name_zh }}
      </template>
      <template v-else-if="scoreLanguage === 'en_US'">
        {{ info.league_name_sb ? info.league_name_sb : info.league_name_zh }}
      </template>
      <template v-else>
        {{ info.league_name_zh }}
      </template>
    </div>
    <div class="time">{{ info.match_time }}</div>
    <div class="statues" :class="{ wait: info.state === 0 }">
      <template v-if="info.state >= 1 && info.state <= 5">
        {{ info.on_time }}'
      </template>
      <template v-if="info.state == 0"> 未 </template>
      <template v-if="info.state < 0">
        {{ state[info.state] ? state[info.state] : "完" }}
      </template>
    </div>
    <div class="home">
      <span v-if="info.home_yellow && scoreCardShow" class="yellow">{{
        info.home_yellow
      }}</span>
      <span v-if="info.home_red && scoreCardShow" class="red">{{
        info.home_red
      }}</span>
      <a :href="`/detail/1/${info.tournament_id}`" target="_blank" class="name">
        <template v-if="scoreLanguage === 'zh_TW'">
          {{ info.home_name_zht ? info.home_name_zht : info.home_name_zh }}
        </template>
        <template v-else-if="scoreLanguage === 'en_US'">
          {{ info.home_name_sb ? info.home_name_sb : info.home_name_zh }}
        </template>
        <template v-else>
          {{ info.home_name_zh }}
        </template>
      </a>
    </div>
    <div class="score" :class="{ wait: info.state === 0 }">
      <template v-if="info.state === 0"> - - </template>
      <template v-else> {{ info.home_score }}-{{ info.away_score }} </template>
    </div>
    <div class="away">
      <a :href="`/detail/1/${info.tournament_id}`" target="_blank" class="name">
        <template v-if="scoreLanguage === 'zh_TW'">
          {{ info.away_name_zht ? info.away_name_zht : info.away_name_zh }}
        </template>
        <template v-else-if="scoreLanguage === 'en_US'">
          {{ info.away_name_sb ? info.away_name_sb : info.away_name_zh }}
        </template>
        <template v-else>
          {{ info.away_name_zh }}
        </template>
      </a>
      <span v-if="info.away_yellow && scoreCardShow" class="yellow">{{
        info.away_yellow
      }}</span>
      <span v-if="info.away_red && scoreCardShow" class="red">{{
        info.away_red
      }}</span>
    </div>
    <div class="half" :class="{ wait: info.state === 0 }">
      <template v-if="info.state === 0"> - - </template>
      <template v-else>
        {{ info.home_score_up }}-{{ info.away_score_up }}
      </template>
    </div>
    <div class="corner" :class="{ wait: info.state === 0 }">
      <img src="@/assets/img/score/corner.png" alt="" />
      <template v-if="info.state === 0">
        <span>- -</span>
      </template>
      <template v-else>
        <span>{{ info.home_corner }}-{{ info.away_corner }}</span>
      </template>
    </div>
    <div class="success">
      <template v-if="info.home_score - info.away_score > 0">
        <span class="success">胜</span>
      </template>
      <template v-else-if="info.home_score - info.away_score < 0">
        <span class="fail">负</span>
      </template>
      <template v-else>
        <span>平</span>
      </template>
    </div>
    <div class="handicap">{{ concedeText }}</div>
    <div class="goals" :style="{ color: goalsText.color }">
      {{ goalsText.text }}
    </div>
    <a :href="`/detail/1/${info.tournament_id}`" target="_blank">
      <div class="data">析</div>
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "footballCompletedItem",
  props: {
    info: Object,
    collectIds: Array,
  },
  data() {
    return {
      state: {
        "-1": "完场",
        "-10": "取消",
        "-11": "待定",
        "-12": "腰斩",
        "-13": "中断",
        "-14": "推迟",
      },
      bClass: "",
      cClass: "",
      eClass: "",
      fClass: "",
    };
  },
  computed: {
    ...mapState({
      scoreLanguage: "scoreLanguage",
      scoreCardShow: "scoreCardShow",
    }),
    concedeText() {
      const { a, b } = this.info;
      let text = "";
      try {
        if (b) {
          text = JSON.parse(b)[1][0];
        } else if (a) {
          text = JSON.parse(a)[1][0];
        }
      } catch (error) {}
      return text;
    },
    goalsText() {
      let textInfo = {
        color: "",
        text: "",
      };
      const totalScore = (this.info.home_score + this.info.away_score) * 100;
      try {
        if (this.info.a) {
          const odds = JSON.parse(this.info.a);
          const oddsScore = parseInt(odds["3"][0]) * 100;
          if (totalScore > oddsScore) {
            textInfo = {
              text: "大",
              color: "#FF6B00",
            };
          } else if (totalScore == oddsScore) {
            textInfo = {
              text: "走",
              color: "#DE9C3B",
            };
          } else if (totalScore < oddsScore) {
            textInfo = {
              text: "小",
              color: "#28C927",
            };
          }
        }
      } catch (e) {}
      return textInfo;
    },
  },
  watch: {
    "info.b": {
      handler(nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.bClass = "up";
        }
        if (nVal && oVal && nVal < oVal) {
          this.bClass = "down";
        }
        const timer = setTimeout(() => {
          this.bClass = "";
          clearTimeout(timer);
        }, 3000);
      },
    },
    "info.c": {
      handler(nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.cClass = "up";
        }
        if (nVal && oVal && nVal < oVal) {
          this.cClass = "down";
        }
        const timer = setTimeout(() => {
          this.cClass = "";
          clearTimeout(timer);
        }, 3000);
      },
    },
    "info.e": {
      handler(nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.eClass = "up";
        }
        if (nVal && oVal && nVal < oVal) {
          this.eClass = "down";
        }
        const timer = setTimeout(() => {
          this.eClass = "";
          clearTimeout(timer);
        }, 3000);
      },
    },
    "info.f": {
      handler(nVal, oVal) {
        if (nVal && oVal && nVal > oVal) {
          this.fClass = "up";
        }
        if (nVal && oVal && nVal < oVal) {
          this.fClass = "down";
        }
        const timer = setTimeout(() => {
          this.fClass = "";
          clearTimeout(timer);
        }, 3000);
      },
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.football-item-wrap {
  display: flex;
  height: 54px;
  line-height: 54px;
  background: #fff;
  overflow: hidden;
  color: #000;
  font-size: 14px;
  justify-content: space-between;
  text-align: center;

  .league {
    width: 92px;
  }

  .time {
    width: 92px;
  }

  .statues {
    width: 92px;
    color: #df1111;

    &.wait {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .home {
    justify-content: end;
  }

  .home,
  .away {
    width: 148px;
    white-space: nowrap;
    display: flex;
    color: rgba(0, 0, 0, 0.9);
    align-items: center;
    gap: 4px;

    .name {
      max-width: 116px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      color: #000000;
    }

    .rank {
      font-size: 12px;
    }

    .yellow {
      text-align: center;
      width: 12px;
      height: 16px;
      line-height: 16px;
      background: url("../../assets/img/score/yellow-card.png") no-repeat;
      color: #fff;
      font-size: 12px;
    }

    .red {
      text-align: center;
      width: 12px;
      height: 16px;
      line-height: 16px;
      background: url("../../assets/img/score/red-card.png") no-repeat;
      color: #fff;
      font-size: 12px;
    }
  }

  .score {
    width: 52px;
    color: #df1111;

    &.wait {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .half {
    width: 82px;

    &.wait {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .corner {
    width: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    img {
      display: block;
      width: 20px;
      height: 20px;
    }

    &.wait {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .data {
    width: 50px;
    cursor: pointer;
  }

  .success {
    width: 100px;
    color: #2151cc;

    .success {
      color: #ff5858;
    }

    .fail {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .handicap {
    width: 100px;

    img {
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .goals {
    width: 100px;
  }

  .data {
    width: 100px;
    color: #000000;
    cursor: pointer;
  }

  &:hover {
    background-color: #f8faff;
  }
}
</style>
