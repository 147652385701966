<template>
  <div class="basketball-list-wrap">
    <div class="basketball-list-wrap">
      <template v-if="routerName === 'collect'">
        <!-- 收藏赛事 -->
        <template v-if="userCollectList.length > 0">
          <div class="basketball-list">
            <template v-for="(item, idx) in userCollectList">
              <basketballItem :info="item" :key="`start-${item.tournament_id}-${idx}`" :collectIds="localCollectIds" @getAnchor="getAnchor" @toTop="toTop" @collect="collect"/>
            </template>
          </div>
        </template>
      </template>
      <template v-else-if="routerName === 'completed'">
        <!-- 完赛赛事 -->
        <template v-if="completedList.length > 0">
          <div class="basketball-list">
            <template v-for="(item, idx) in completedList">
              <basketballItem :info="item" :key="`start-${item.tournament_id}-${idx}`" :collectIds="localCollectIds" @getAnchor="getAnchor" @toTop="toTop" @collect="collect"/>
            </template>
          </div>
        </template>
      </template>
      <template v-else-if="routerName === 'schedule'">
        <!-- 未来赛事 -->
        <template v-if="scheduleList.length > 0">
          <div class="basketball-list">
            <template v-for="(item, idx) in scheduleList">
              <basketballItem :info="item" :key="`start-${item.tournament_id}-${idx}`" :collectIds="localCollectIds" @getAnchor="getAnchor" @toTop="toTop" @collect="collect"/>
            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <!-- 置顶赛事 -->
        <template v-if="topList.length > 0">
          <div class="basketball-list">
            <template v-for="(item, idx) in topList">
              <basketballItem :info="item" :key="`start-${item.tournament_id}-${idx}`" :collectIds="localCollectIds" @getAnchor="getAnchor" @toTop="toTop" @collect="collect"/>
            </template>
          </div>
        </template>
        <!-- 进行中赛事 -->
        <template v-if="onStartList.length > 0">
          <div class="on-start statue-title">进行中</div>
          <div class="basketball-list">
            <template v-for="(item, idx) in onStartList">
              <basketballItem :info="item" :key="`start-${item.tournament_id}-${idx}`" :collectIds="localCollectIds" @getAnchor="getAnchor" @toTop="toTop" @collect="collect"/>
            </template>
          </div>
        </template>
        <template v-if="onWaitList.length > 0">
          <div class="on-wait statue-title">未开始</div>
          <div class="basketball-list">
            <template v-for="(item, idx) in onWaitList">
              <basketballItem :info="item" :key="`wait-${item.tournament_id}-${idx}`" :collectIds="localCollectIds" @getAnchor="getAnchor" @toTop="toTop" @collect="collect"/>
            </template>
          </div>
        </template>
        <template v-if="onEndList.length > 0">
          <div class="on-end statue-title">已结束</div>
          <div class="basketball-list">
            <template v-for="(item, idx) in onEndList">
              <basketballItem :info="item" :key="`end-${item.tournament_id}-${idx}`" :collectIds="localCollectIds" @getAnchor="getAnchor" @toTop="toTop" @collect="collect"/>
            </template>
          </div>
        </template>
      </template>
    </div>

    <a-drawer :width="404" :visible="drawer" :direction="direction" :modal="false" @close="handleClose">
      <template slot="title">
        <div class="drawer-title">解说</div>
      </template>
      <div class="drawer-body">
        <ul>
          <li class="anchor-item" v-for="(i, idx) in anchors" :key="`${idx}-${i.member_id}`">
            <a
                :href="`/detail/2/${i.tournament_id}/${i.member_id}`"
                target="_blank"
              >
              <div class="anchor-img">
                <img :src="i.face" alt="">
              </div>
              <p class="anchor-name">{{i.nickname}}</p>
            </a>
          </li>
        </ul>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import basketballItem from "@/components/score/basketballItem.vue"
import wsService from '../../utils/websocket.js'
import storage from '@/utils/storage'
import { mapActions, mapState } from 'vuex';

export default {
  name: 'basketball-score',
  components: {
    basketballItem
  },
  props: {
    filterLeagues: Array
  },
  data () {
    return {
      list: [],
      localTopIds: [],
      userCollectList: [],
      ftTimeWs: null,
      ftLiveWs: null,
      drawer: false,
      direction: 'rtl',
      anchors: [],
      lock:false,
    }
  },
  computed: {
    ...mapState({
      scoreLanguage: 'scoreLanguage'
    }),
    routerName () {
      return this.$route.name
    },
    localCollectIds () {
      return Array.from(this.userCollectList, ({tournament_id}) => tournament_id) || []
    },
    completedList () {
      return this.list.filter(i => i.state == -1 && this.filterLeagues.includes(i.league_id)) || []
    },
    scheduleList () {
      return this.list.filter(i => i.state == 0 && this.filterLeagues.includes(i.league_id)) || []
    },
    filterList () {
      return this.list.filter(i => this.localTopIds.indexOf(i.tournament_id) < 0 && this.filterLeagues.includes(i.league_id)) || []
    },
    onStartList () {
      return this.filterList.filter(i => [1,2,3,4,5,6,7,50].includes(i.state)) || []
    },
    onWaitList () {
      return this.filterList.filter(i => i.state == 0) ||  []
    },
    onEndList () {
      return this.filterList.filter(i => i.state < 0) ||  []
    },
    topList () {
      return this.list.filter(i => this.localTopIds.includes(i.tournament_id)) ||  []
    }
  },
  methods: {
    getAnchor (info) {
      this.$api.get(`/web/get_public_anchor?tournament_id=${info.tournament_id}&type=2`).then((res) => {
        this.drawer = true
        this.anchors = res.data.more
      })
    },
    handleClose () {
      this.drawer = false
      this.anchors = []
    },
    getList () {
      const today = dayjs().format("YYYY-MM-DD")
      let day = this.$route.query.day ? dayjs(this.$route.query.day).format('YYYY-MM-DD') : today

      if (['completed', 'schedule'].includes(this.$route.name)) {
        day = day || dayjs(new Date()).format('YYYY-MM-DD')
      }
      let url = `/web/web_basketball_score_list?day=${day}`
      if (['completed'].includes(this.$route.name)) {
        url = `${url}&state=-1`
      }
      if (['schedule'].includes(this.$route.name)) {
        url = `${url}&state=0`
      }

      this.$api.get(url).then((res) => {
        this.list = res.data || []
      })
    },
    getCollectList () {
      this.$api.get(`/web/get_match_follow_list?genre=2`).then((res) => {
        if (res.code === 200) {
          this.userCollectList = res.data || []
        }
      })
    },
    toTop (info) {
      if (!this.localTopIds.includes(info.tournament_id)) {
        this.localTopIds.push(info.tournament_id)
      } else {
        const idx = this.localTopIds.indexOf(info.tournament_id)
        this.localTopIds.splice(idx, 1)
      }

      localStorage.setItem('basketball_top_ids', JSON.stringify(this.localTopIds))
    },
    collect (info) {

      var token = storage.get("token");
      if (!token) {
        this.$parent.$refs.header.toLogin();
        return false
      } else {
        if(this.lock){
          return false
        }
        this.lock = true
        let follow = 1
        if (!this.localCollectIds.includes(info.tournament_id)) {
          follow = 1
        } else {
          follow = 0
        }
        this.$api.post(`/web/set_match_follow`, {
          tournament_id: info.tournament_id,
          genre: 2,
          is_follow: follow
        }).then(res => {
          if (res.code === 200) {
            if (follow == 1) {
              this.userCollectList.push(info)
            } else {
              const idx = this.userCollectList.findIndex(({tournament_id}) => info.tournament_id === tournament_id)
              this.userCollectList.splice(idx, 1)
            }
            this.lock = false
          }
        })
      }
    },
    // 建立连接、发起ws请求，以心跳方式，向服务端发送数据
    createWs () {
      // this.ftTimeWs = new WebSocket(this.wsUrl);
      // this.ftLiveWs = new WebSocket(this.wsUrl);
      // // 若为对象类型，以回调方式发送
      // websocketCommand(this.ftTimeWs, 'create', JSON.stringify({'type': 'init', 'task_id': 'basketball_list'}), this.receiveMsg);
      // websocketCommand(this.ftLiveWs, 'create', JSON.stringify({'type': 'init', 'task_id': 'basketballlive'}), this.receiveMsg);
      this.ftTimeWs = new wsService(this.wsUrl, 5000, 3);
      this.ftLiveWs = new wsService(this.wsUrl, 5000, 3);
      this.ftTimeWs.init();
      this.ftLiveWs.init();
      this.ftTimeWs.onOpen(() => {
        this.ftTimeWs.sendMessage({ type: "init", task_id: "basketball_list" });
      });
      this.ftLiveWs.onOpen(() => {
        this.ftLiveWs.sendMessage({ type: "init", task_id: "basketballlive" });
      });
      this.ftTimeWs.onMessage = this.receiveMsg;
      this.ftLiveWs.onMessage = this.receiveMsg;
    },
    receiveMsg (msg) {
      // 比赛时间更新
      if (msg.type === 'on_time' || msg.type === 'live') {
        const oList = JSON.parse(JSON.stringify(this.list))
        const nList = oList.map(item => {
          const game = msg.result.filter(wsGame => wsGame.tournament_id == item.tournament_id)[0] || {}
          const newGame = Object.assign({}, item, game)
          return newGame
        })
        this.list = nList
      }
      // 指数推送 处理 task_id: basketball_list 的指数推送，basketballlive 的不管
      if (msg.type === 'odds' && !msg.result.category) {
        const oList = JSON.parse(JSON.stringify(this.list))
        const nList = oList.map(item => {
          const game = msg.result.filter(wsGame => wsGame.tournament_id == item.tournament_id)[0] || {}
          let oFigure = {}
          if (item.c && typeof item.c === "string") {
            oFigure = Object.assign({}, oFigure, JSON.parse(item.c))
          }
          if (item.b && typeof item.b === "string") {
            oFigure = Object.assign({}, oFigure, JSON.parse(item.b))
          }
          if (item.a && typeof item.a === "string") {
            oFigure = Object.assign({}, oFigure, JSON.parse(item.a))
          }

          let figure = {}
          if (game && game.c && typeof game.c === "string") {
            figure = JSON.parse(game.c)
            game.c = JSON.stringify(Object.assign({}, oFigure, figure))
          }
          if (game && game.b && typeof game.b === "string") {
            figure = JSON.parse(game.b)
            game.b = JSON.stringify(Object.assign({}, oFigure, figure))
          }
          if (game && game.a && typeof game.a === "string") {
            figure = JSON.parse(game.a)
            game.a = JSON.stringify(Object.assign({}, oFigure, figure))
          }

          const newGame = Object.assign({}, item, game)
          return newGame
        })
        this.list = nList
      }
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.query) {
        this.list = []
        this.getList();
        var token = storage.get("token");
        if (token) {
          this.getCollectList();
        }
      }
    },
  },
  mounted () {
    this.localTopIds = JSON.parse(localStorage.getItem('basketball_top_ids')) || []
    this.getList()
    this.createWs()
    var token = storage.get("token");
    if (token) {
      this.getCollectList()
    }
  },
  beforeDestroy () {
    websocketCommand(this.ftTimeWs,'close');
    websocketCommand(this.ftLiveWs,'close');
    this.ftTimeWs = null;
    this.ftLiveWs = null;
  }
}
</script>

<style lang="less" scoped>
.basketball-list-wrap {
  margin-top: 16px;
  .basketball-list-wrap {
    .statue-title {
      height: 54px;
      line-height: 54px;
      position: relative;
      padding-left: 20px;
      font-size: 16px;
      color: #000;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 16px;
        height: 22px;
        width: 6px;
        border-radius: 4px;
      }
      &.on-start {
        &::after {
          background: #28C927;
        }
      }

      &.on-wait {

        &::after {
          background: #FFCD00;
        }
      }

      &.on-end {
        &::after {
          background: #CCCCCC;
        }
      }
    }
    .basketball-list {
      border-radius: 4px;
      overflow: hidden;
    }
  }


}
.drawer-title {
    color: #000;
    font-weight: bold;
    font-size: 16px;
  }
  .drawer-body {
    padding: 0 20px;
    ul {
      display: flex;
      flex-wrap: wrap;
      padding:0;
      gap: 20px;
      li {
        width: 74px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
        .anchor-img {
          width: 46px;
          height: 46px;
          padding: 2px;
          border: 1px solid #FFCD00;
          border-radius: 50%;
          position: relative;
          &::after {
            position: absolute;
            bottom: -8px;
            content: '直播中';
            font-size: 12px;
            width: 48px;
            height: 18px;
            font-size: 12px;
            border-radius: 18px;
            background: #FFCD00;
            text-align: center;
            line-height: 18px;
            left: -1px;
            transform: scale(0.7);
          }
          img {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        a {
          width: 74px;
          display: block;
          display: flex;
          flex-direction: column;
          align-items: center;

        }
        .anchor-name {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 20px;
          margin-top: 10px;
          font-size: 12px;
          text-align: center;
          color: #000000;
        }
      }
    }
  }
</style>
